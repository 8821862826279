@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

body {
  background-color: #59b3ff;
  text-align: center;
  font-family: Verdana;
  background-image: url("/assets/img/background.jpg");

  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
}

div.bambou {
  border-style: solid;
  border-width: 17px;
  border-image-source: url("/assets/img/border_bambou.png");
  border-image-slice: 17;
  border-image-repeat: round;
  background-color: rgba(0,0,0,.4);
  color: white;
}

div.panel {
  background-color: rgba(0,0,0,.4);
  color: white;
  padding: 5px;
  margin-bottom: 20px;
}

div.panel a {
  color: rgba(12, 11, 124, 0.72);
  font-weight: bold;
  text-decoration: underline;
}


@media screen and (max-width: 500px) {
  div.bambou {
    border-width: 8px;
  }
}

strong.dino  {
  color: orange;
  text-shadow: 2px 2px 1px #600;
  font-family: Comic sans MS;
  text-transform: uppercase;
}

a.external {
  color: orange !important;
  text-decoration: none !important;
  text-shadow: 2px 2px 1px #600;
  font-weight: bold;
}
